
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { SearchItem } from '@/components/context-menu/model';

import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Button from '@/components/ui/button';
import Icon from '@/components/ui/icon/Icon.vue';
import { CategoriesResponse } from '@/models/dto/responses/categories.response';

@Component({
  components: {
    Icon,
    ContextMenuSpawner,
    Button,
  },
})
export default class DocumentMeta extends Vue {
  @Prop({ required: true }) title: string;
  @Prop() selectButton: string;
  @Prop() hideBtn: CategoriesResponse;
  @Prop() selected: Array<SearchItem>;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) noBorder: boolean;

  // Открыт ли попап с контекстным меню


  get hideButton() {
    return !!this.$slots.default[0].children;
  }
  @Emit('resetSearch')
  resetSearch() {
    return true
  }
}
