
import Component, { mixins } from 'vue-class-component';
import ButtonLike from '@/mixins/ButtonLike';

@Component({
  name: 'ButtonGroup',
})
export default class ButtonGroup extends mixins(ButtonLike) {
  readonly baseClass = 'button-group';
  readonly BUTTON_GROUP = true;

  created() {
    const props: { type?: string } = this.$options.propsData;
    if (!props?.type) props.type = 'plain';
  }
}
