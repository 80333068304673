
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { Portal } from '@linusborg/vue-simple-portal';
  import GeneralModule from '@/store/modules/general/GeneralModule';
  import ContextMenu from './ContextMenu.vue';
  import RolesModule from '@/store/modules/roles/RolesModule';
  
  export enum ContextMenuEvents {
    open = 'open',
    close = 'close',
    protect = 'protect',
    childOpen = 'childOpen',
    childClose = 'childClose',
  }
  
  @Component({
    name: 'ContextMenuSpawner',
    components: {
      Portal,
      ContextMenu,
    },
  })
  export default class ContextMenuSpawnerHover extends Vue {
    public $isMobile: boolean;
    private _uid: number;
  
    public isOpen = false;
    public isEnter = false;
    public isProtected = false;
    public throttledOnResize: Function;
    public correctionLeft = 0;
    public correctionTop = 0;
    public openedChildren: Array<ContextMenuSpawnerHover> = [];
  
    @Prop() items: unknown;
    @Prop({default: true}) closeChild: boolean;
    @Prop({default: true}) openByClick: boolean;
    @Prop({default: false}) openByHover: boolean;
    @Prop({ default: false }) noBorder: boolean;
    @Prop({ default: 'span' }) tag: string;
    @Prop() getInstance: Function;
  
    public get isHidden(): boolean {
      return this.$isMobile && !!this.openedChildren.length;
    }
  
    get spawner(): HTMLElement {
      if (this.$slots.default[0].elm) {
        return this.$slots.default[0].elm as HTMLElement;
      } else {
        return this.$el as HTMLElement;
      }
    }
    isEnterMouse(e:any)
    {
      if(e.type === 'mouseenter')
      {
        this.isEnter = true;
        this.isOpen = true;
      }
      else if(e.type === 'mouseleave')
      {
        this.isOpen = false;
        this.isEnter  = false;
      }
    }
    get parentInstances(): Array<ContextMenuSpawnerHover> {
      let currentParent = this.$parent;
      const parents: Array<ContextMenuSpawnerHover> = [];
  
      while (currentParent) {
        if (currentParent instanceof ContextMenuSpawnerHover) {
          parents.push(currentParent);
        }
  
        currentParent = currentParent.$parent;
      }
  
      return parents;
    }
  
    onTransitionEnter(el: HTMLElement) {
      const rect = el.getBoundingClientRect();
      const right = window.innerWidth - rect.right;
      const bottom = window.innerHeight - rect.bottom;
      this.correctionLeft = right < 0 ? right - 25 : 0;
      this.correctionTop = bottom < 0 ? bottom - 25 : 0;
    }
  
    getSpawnerRect(): DOMRect {
      return this.spawner.getBoundingClientRect();
    }
  
    getStyles() {
      if (!this.spawner) return {};
  
      const rect = this.getSpawnerRect();
      const top = rect.top + window.scrollY + this.correctionTop;
      const left = rect.left + window.scrollX + rect.width + this.correctionLeft;
  
      return {
        top: `${top}px`,
        left: `${left}px`,
      };
    }
  
    toggle(e: any = null) {
        this.isEnter = !this.isEnter;
      if(this.openByHover && (e.type === 'mouseenter' || e.type === 'mouseleave'))
      {
        if(this.closeChild === false)
        {
          this.isOpen = !this.isOpen;
        }
      }
      else if(e.type === 'click' && this.openByClick)
      {
        this.isOpen = !this.isOpen;
      }
    }
  
    open() {
      this.isOpen = true;
    }
  
    close() {
      this.isOpen = false;
    }
  
    emitOnParentMenus(event: string, payload?: unknown) {
      this.parentInstances.forEach(instance => {
        instance.$emit(event, payload);
      });
    }
  
    protectParents() {
      this.emitOnParentMenus(ContextMenuEvents.protect);
    }
  
    // # Event handlers
  
    onResize() {
      if (this.isOpen) this.close();
    }
  
    onClickOutside() {
      RolesModule.setClosedContext(true)
      if (this.isProtected || this.$isMobile) return;
      this.close();
    }
    
    onProtect() {
      this.isProtected = true;
  
      setTimeout(() => {
        this.isProtected = false;
      }, 0);
    }
  
    onClildOpen(child: ContextMenuSpawnerHover) {
      this.openedChildren.push(child);
    }
  
    onChildClose(child: ContextMenuSpawnerHover) {
      const index = this.openedChildren.indexOf(child);
      this.openedChildren.splice(index, 1);
    }
  
    @Watch('isOpen')
    onChange(value: boolean) {
      const id = this._uid.toString();
      if (value) {
        this.$emit(ContextMenuEvents.open);
        this.emitOnParentMenus(ContextMenuEvents.childOpen, this);
        if (this.$isMobile) GeneralModule.addScrollBlocker(id);
      } else {
        this.$emit(ContextMenuEvents.close);
        this.emitOnParentMenus(ContextMenuEvents.childClose, this);
        GeneralModule.removeScrollBlocker(id);
      }
    }
  
    // # Hooks
  
    created() {
      if (this.getInstance) this.getInstance(this);
    }
  
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.$on(ContextMenuEvents.protect, this.onProtect);
      this.$on(ContextMenuEvents.childOpen, this.onClildOpen);
      this.$on(ContextMenuEvents.childClose, this.onChildClose);
    }
  
    beforeDestroy() {
      GeneralModule.removeScrollBlocker(this._uid.toString());
      window.removeEventListener('resize', this.onResize);
      this.$off(ContextMenuEvents.protect, this.onProtect);
      this.$off(ContextMenuEvents.childOpen, this.onClildOpen);
      this.$off(ContextMenuEvents.childClose, this.onChildClose);
    }
  }
  